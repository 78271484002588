import catchError from 'utils/catchError'
import { supabase } from 'clients/supabase'

import axios from 'axios'
import { isProduction } from '../../../constants'

export async function updateLastLogin(id?: string) {
	try {
		if (id) {
			const { error } = await supabase
				.from('core_adoptions')
				.update({
					last_login: new Date().toISOString()
				})
				.eq('id', id)
			if (error) throw new Error(error.message)
			isProduction && axios.patch(`https://coreadoption.americanreading.com/api/algolia/object/${id}`)
		}
	} catch (error) {
		catchError(error)
	}
}
