import React, { FC } from 'react'
import { useUser } from '@supabase/auth-helpers-react'
import CoreAdoptionProvider from 'context/CoreAdoption'
import { isBrowser } from 'utils/isBrowser'
import { navigate } from 'gatsby'

interface Props {
	component: FC
	[x: string]: any
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
	const user = useUser()
	if (!user && isBrowser) {
		navigate('/login')
	}

	return (
		<CoreAdoptionProvider>
			<Component {...rest} />
		</CoreAdoptionProvider>
	)
}

export default PrivateRoute
