import { useUser } from '@supabase/auth-helpers-react'
import PageComponentsRenderer from 'components/core-adoption/builder/PageComponentsRenderer'
import { initLogRocket } from 'components/core-adoption/functions/initLogRocket'
import { updateLastLogin } from 'components/core-adoption/functions/updateLastLogin'
import Layout from 'components/Layout'
import { CoreAdoptionContext } from 'context/CoreAdoption'
import LogRocket from 'logrocket'
import React, { useContext, useEffect } from 'react'

LogRocket.init('zpy02v/arc-react-gatbsy')

export default function BuilderTemplate() {
	const user = useUser()
	const { account } = useContext(CoreAdoptionContext)

	useEffect(() => {
		if (user) initLogRocket(user.id, user.email)
	}, [user])

	// Update the accounts last login field
	useEffect(() => {
		if (account) updateLastLogin(account.id)
	}, [account])

	return (
		<Layout pageName="Core Adoption" showHeader={false} showFooter={false}>
			<PageComponentsRenderer />
		</Layout>
	)
}
