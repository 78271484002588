import axios from 'axios'
import AccessBar from 'components/core-adoption/builder/AccessBar'
import AnalyticsVisitorModal from 'components/core-adoption/builder/AnalyticsVisitorModal'
import Disclaimer from 'components/core-adoption/builder/Disclaimer'
import { CoreAdoptionContext } from 'context/CoreAdoption'
import React, { useContext, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { isBrowser } from 'utils/isBrowser'

export default function PageComponentsRenderer() {
	const { account, sessionId: id, setAnalyticsModalOpen, initAnalytics } = useContext(CoreAdoptionContext)
	const [isTimedOut, setIsTimedOut] = useState(false)

	const updateTime = (id: number | null) => {
		try {
			if (id) axios.post('/api/core-adoption/update-analytics-time', { id, time: getTotalActiveTime() })
		} catch (error) {
			console.log(error)
		}
	}

	const handleOnIdle = () => {
		updateTime(id)
		setIsTimedOut(true)
	}

	const handleOnActive = () => {
		setIsTimedOut(false)
	}

	const { getTotalActiveTime } = useIdleTimer({
		timeout: 15000,
		onIdle: handleOnIdle,
		onActive: handleOnActive
	})

	useEffect(() => {
		let interval: NodeJS.Timer
		if (!isTimedOut) {
			interval = setInterval(() => updateTime(id), 20000) // Call the API route every 30 seconds
		}
		if (isTimedOut) {
			// @ts-ignore
			clearInterval(interval) // Clear the interval when isTimedOut is true
		}
		return () => clearInterval(interval) // Clear the interval when the component unmounts
	}, [isTimedOut])

	useEffect(() => {
		if (!account) {
			console.log('%c Account Not Ready ', 'background: #2563eb; color: #fff')
		} else if (account.disable_analytics) {
			console.log('%c Account Analytics Disabled ', 'background: #2563eb; color: #fff')
		} else if (localStorage.getItem('coreAdoptionVisitor')) {
			console.log('%c Visitor Found ', 'background: #2563eb; color: #fff')
		} else {
			setAnalyticsModalOpen(true)
		}
	}, [account])

	useEffect(() => {
		if (!account) {
			console.log('%c Account Not Ready ', 'background: #2563eb; color: #fff')
		} else if (id) {
			console.log('%c Session ID Found ', 'background: #2563eb; color: #fff')
		} else if (account.disable_analytics) {
			console.log('%c Account Analytics Disabled ', 'background: #2563eb; color: #fff')
		} else {
			initAnalytics()
		}
	}, [account])

	const onVisibilityChange = () => {
		if (isBrowser && document.visibilityState === 'hidden') updateTime(id)
	}

	useEffect(() => {
		if (isBrowser) {
			document.addEventListener('visibilitychange', onVisibilityChange)
			return () => document.removeEventListener('visibilitychange', onVisibilityChange)
		}
	}, [])

	if (!account)
		return (
			<div className="flex h-screen w-screen items-center justify-center">
				<div className="flex flex-col items-center">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="animate-spin" height={60} width={60}>
						<path
							d="M85.4 17.6c-.7 2.1-1.6 4.1-2.7 6C88.5 30.9 92 40 92 50c0 23.2-18.8 42-42 42S8 73.2 8 50c0-10.1 3.6-19.3 9.5-26.6-1-1.9-1.9-4-2.6-6.1C6.9 25.9 2 37.4 2 50c0 26.5 21.5 48 48 48s48-21.5 48-48c0-12.5-4.8-23.8-12.6-32.4z"
							fill="#E6E6E6"
						/>
					</svg>
					<p className="mt-2">Loading your account...</p>
				</div>
			</div>
		)

	return (
		<>
			<AccessBar />
			{account.order
				.filter((item) => item.active === true)
				.map((item) => {
					if (item.component) return <item.component key={item.id} {...item} />
				})}
			<Disclaimer />
			<AnalyticsVisitorModal />
		</>
	)
}
