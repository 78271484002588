import { CoreAdoptionContext } from 'context/CoreAdoption'
import { formatDistanceToNow } from 'date-fns'
import React, { useContext, useState } from 'react'
import Collapse from 'react-collapse'

export default function AccessBar() {
	const [showBar, setShowBar] = useState(true)
	const { account } = useContext(CoreAdoptionContext)

	return (
		// @ts-ignore
		<Collapse
			isOpened={showBar}
			theme={{
				collapse: 'ReactCollapse--collapse transition-all duration-300 ease-in-out',
				content: 'ReactCollapse--content'
			}}
		>
			<div className="relative w-full">
				<p className="mb-0 bg-red-500 py-2 text-center text-white">
					Access expires {account?.expiration_date ? formatDistanceToNow(new Date(account.expiration_date), { addSuffix: true }) : '30'}
				</p>
				<div className="absolute text-white" style={{ right: 15, top: 10 }}>
					<button onClick={() => setShowBar(false)} className="scale-x-125 transform hover:text-red-900">
						X
					</button>
				</div>
			</div>
		</Collapse>
	)
}
