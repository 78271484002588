import { Col, Row, Section } from 'components/grid'
import { CoreAdoptionContext } from 'context/CoreAdoption'
import React, { useContext } from 'react'

export default function Disclaimer() {
	const { language } = useContext(CoreAdoptionContext)
	return (
		<Section>
			<Row className="py-10">
				<Col>
					<p className="text-gray-body font-bold">{language === 'english' ? 'Disclaimer' : 'Aviso legal'}</p>
					<p className="text-sm font-light">
						{language === 'english'
							? 'The teacher frameworks provided physically and/or digitally include proprietary information and must be excluded from public release. These frameworks include: IRLA, ENIL, 100 Book Challenge Handbook, ARC Literacy Labs, Research Labs, and Genre Labs. SchoolPace also includes proprietary information and the logins provided for this tool must be excluded from public release.'
							: 'Los manuales para los docentes provistos física y/o digitalmente incluyen información patentada y no se deben difundir públicamente. Estos manuales incluyen IRLA, ENIL, 100 Book Challenge Handbook, ARC Literacy Labs, Research Labs y Genre Labs. SchoolPace también incluye información patentada y los detalles de acceso provistos para esta herramienta no se deben difundir públicamente.'}
					</p>
					<p className="text-sm font-light">
						{language === 'english'
							? `Both physical and digital frameworks specified are Copyright © ${new Date().getFullYear()} by American Reading Company. All rights reserved, including the right of reproduction in whole or in part in any form. The contents of these publications may not be reproduced or used in any form except to facilitate implementation of ARC Core in classrooms registered with American Reading Company.`
							: `Los manuales físicos y digitales indicados están registrados bajo Copyright © ${new Date().getFullYear()} por American Reading Company. Todos los derechos reservados, incluyendo el derecho a la reproducción en su totalidad o en parte por cualquier medio. El contenido de estas publicaciones no se debe reproducir o utilizar de ninguna manera excepto para facilitar la implementación de ARC Core en salones de clase registradas con American Reading Company.`}
					</p>
				</Col>
			</Row>
		</Section>
	)
}
