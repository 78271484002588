import React from 'react'
import { Router } from '@reach/router'
import BuilderTemplate from 'components/core-adoption/builder/BuilderTemplate'
import PrivateRoute from 'components/global/PrivateRoute'

const CoreAdoption = () => {
	return (
		<Router basepath="/core-adoption">
			<PrivateRoute path="/" component={BuilderTemplate} />
		</Router>
	)
}

export default CoreAdoption
