import { Button } from '@arckit/ui'
import { Label, TextInput, ValidationError } from 'components/global/FormInputs'
import Modal from 'components/global/Modal'
import { CoreAdoptionContext } from 'context/CoreAdoption'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

export default function AnalyticsVisitorModal() {
	const { visitor, handleSetVisitor, analyticsModalOpen, setAnalyticsModalOpen, submitVisitorForm, formLoading } = useContext(CoreAdoptionContext)
	const { register, errors, handleSubmit } = useForm({
		mode: 'onSubmit'
	})
	return (
		<Modal noClose title="Welcome to ARC Core!" isOpen={analyticsModalOpen} handleClose={() => setAnalyticsModalOpen(false)}>
			<p className="mb-6">To improve your Core Adoption experience, please provide the details below.</p>
			<form noValidate onSubmit={handleSubmit(submitVisitorForm)}>
				<div className="mb-6 flex gap-4">
					<div className="w-1/2">
						<Label name="Full Name" htmlFor="visitorName" required />
						<TextInput
							required
							type="text"
							id="visitorName"
							name="visitorName"
							value={visitor.name}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetVisitor('name', e.target.value)}
							validation={register({
								required: 'Name is required',
								minLength: {
									value: 4,
									message: 'Name must be at least 4 characters'
								}
							})}
						/>
						<ValidationError>{errors.visitorName && errors.visitorName.message}</ValidationError>
					</div>
					<div className="w-1/2">
						<Label name="Email" htmlFor="visitorEmail" required />
						<TextInput
							required
							type="text"
							id="visitorEmail"
							name="visitorEmail"
							value={visitor.email}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetVisitor('email', e.target.value)}
							validation={register({
								required: 'Email is required',
								pattern: {
									// eslint-disable-next-line
									value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Please enter a valid email address'
								}
							})}
						/>
						<ValidationError>{errors.visitorEmail && errors.visitorEmail.message}</ValidationError>
					</div>
				</div>
				<div className="mb-6 flex gap-4">
					<div className="w-1/2">
						<Label name="Phone" htmlFor="visitorPhone" />
						<TextInput
							type="text"
							id="visitorPhone"
							name="visitorPhone"
							value={visitor.phone}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetVisitor('phone', e.target.value)}
						/>
					</div>
					<div className="w-1/2">
						<Label name="Title" htmlFor="visitorTitle" />
						<TextInput
							type="text"
							id="visitorTitle"
							name="visitorTitle"
							value={visitor.title}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetVisitor('title', e.target.value)}
						/>
					</div>
				</div>
				<div className="mb-3">
					<Button loading={formLoading} type="submit" fullWidth>
						Submit
					</Button>
				</div>
			</form>
			<a href="/privacy-policy" target="_blank" className="text-sm" rel="noopener noreferrer">
				By clicking submit, you agree to our Terms of Use & Privacy Policy.
			</a>
		</Modal>
	)
}
